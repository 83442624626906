import * as React from 'react'
import { graphql, Link } from 'gatsby'
import logo_jandJ from "../images/Logo_jandj.png"
import Hero from '../components/Hero'
import Img from "gatsby-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { JsonLd } from "react-schemaorg";
import { Event, BreadcrumbList } from "schema-dts";
import { Helmet } from "react-helmet"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
// import { ReactTinyLink } from 'react-tiny-link';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "surgeoncircle_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webinar_current: file(relativePath: { eq: "webinar_current.jpg" }) {
      childImageSharp {
        fluid(maxWidth:600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webinarnext: file(relativePath: { eq: "webinar_next.jpg" }) {
      childImageSharp {
        fluid(maxWidth:600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
   <JsonLd<Event & BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@graph": [ 
   {
    "@type": "Event",
    name: "Aftershock: Returning to Elective Surgery, a Live Peer Review.",
    description: "Join us for our upcoming webinar - Aftershock: Returning to Elective Surgery, a Live Peer Review.",
    image: "http://www.pansurg.org/webinar_current.jpg",
    startDate: "2020-09-18T08:00:00-00:00",
    endDate: "2020-09-18T18:17:00-00:00",
    eventStatus: "https://schema.org/EventScheduled",
    eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
    location: {
      "@type": "VirtualLocation",
      url: "https://app.livestorm.co/pansurg-1/returning-to-elective-surgery"
    },
    performer: {
      "@type": "PerformingGroup",
      name: "PanSurg"
    },
    organizer: { 
      name: "PanSurg",
      url: "http://www.pansurg.org"
    }
}, 
  {
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "PanSurg",
      "item": "http://www.pansurg.org"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Academy",
      "item": "http://www.pansurg.org/webinars"  
    }] }
  ]
      }}
     /> 
     <Helmet>
    {`<script type="application/ld+json">
         "@context": "https://schema.org",
         "@graph": [ 
   {
     "@type": "BreadcrumbList", 
     "itemListElement": [{
       "@type": "ListItem", 
       "position": 1, 
       "name": "PanSurg",
       "item": "http://www.pansurg.org"  
     },{
       "@type": "ListItem", 
       "position": 2, 
       "name": "Academy",
       "item": "http://www.pansurg.org/webinars"  
     }] }
   ]
   </script>`}
</Helmet>
<GatsbySeo title='PanSurg Academy'
      description='Share experiences, collaborate and help colleagues around the world
      to deliver safer surgical care during COVID and beyond. '/>
  <Hero title="PanSurg Academy"
        subtitle="Education, webinars and experiences; collaborate and help colleagues around the world
        to deliver safer surgical care."
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        activecrumb = 'Academy'
  />
<section className="section is-small" style={{margin:'0rem 0rem'}}>
    <div className="container">
      <div className="content is-thin">
        <p>Webinars will take place via live video-conferencing facilities
        allowing audience participation from around the world. Each webinar will
        have invited panellists with useful and practical knowledge to share as the
        pandemic evolves.</p>
        <p>We have invited practitioners and decision-makers who
        have worked in their respective health systems through the pandemic at various
        stages and can offer their insights on what has been learned. They offer
        important messages of advice to those still facing the crisis and those
        who are about to meet it. The webinar sessions will also incorporate
        questions from the wider community who have registered for the event.
        Webinars will be recorded and posted once completed for future reference.
        </p>
      </div>

    </div>
  </section>
  <hr className="is-divider"/>


  <section className="section">

    <div className="container">
      <div className="content">
</div>
<h1 className="title has-text-darkBlue is-size-3">
Aftershock: Digital Health after COVID-19, a Live Peer Review - Friday 2nd October
    </h1>
    </div>
  </section>
  <section className="section is-small">
    <div className="container content">
    <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Breakfast - Digital Adoption Success & Sustainability How has the COVID19 pandemic accelerated surgical innovation?</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <b>Chairs:</b> Dr. Saira Ghafur - Lead for Digital Health, Institute of Global Health Innovation, Imperial College London.<br />
<b>Panel:</b><br />
Junaid Bajwa - Microsoft Medical Director UK<br />
Nessa Nyrhinen/Tom Whicher - NHS Innovation Fellow, Founder of Dr Doctor/Head of Comms, PR & Events of Dr Doctor<br />
Sam Shah - Director, Faculty of Future Health | Global Digital Advisor | Chief Medical Strategy Officer<br />
Edward St John - Chief Medical Officer at concentric Health<br />
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Data privacy in the post Covid-19 world – how do we get it right?</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <b>Chairs:</b> James Kinross - Senior Lecturer and Consultant in Colorectal surgery, Imperial College London.<br />
<b>Panel:</b><br />
Phil Brown - ABHI Director Regulatory & Compliance<br />
James O-Shaughnessey - Conservative Member @ House of Lords // Health Minister 2016-2018<br />
Stuart Sherman - CEO - Scaled Insights<br />
                </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Digital Health Inequality How do we deliver sustainable digital transformation after Covid19?</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <b>Chair:</b> TBC<br />
<b>Panel:</b><br />
Andrew Davies - ABHI Digital Health Lead<br />
Edmund Willis - Advisor - Social Care Digital Innovation - Local Government Association<br />
Jazz Bhogal - Assistant Director for Health, Education & Youth<br />
                </AccordionItemPanel>
                </AccordionItem>
                </Accordion> <br />
      <div className="columns">
        <div className="column is-6">

        <div style={{maxWidth:'400px', margin: 'auto'}}>
            <a href="https://app.livestorm.co/pansurg-1/digital-health-after-covid-19">
              <Img className="bounce" fluid={data.webinar_current.childImageSharp.fluid} />
            </a>
          </div>
        </div>
     
        <div className="column">
            <div className="tile box has-background-white-ter is-vertical">
          <p className="title is-size-4 has-text-darkBlue">Please register for the next webinar below</p>
              <iframe width="100%" height="470" frameborder="0"
          src="https://app.livestorm.co/p/99ab6180-0809-4104-aa2b-5e21a8718af9/form"></iframe>
        </div>
        </div>

      </div>
    {/* <h1 className="title has-text-darkBlue is-size-3">
      Upcoming Webinars: Friday 29th May, 1700 BST
    </h1>
    <div className="columns">
        <div className="column is-6">

          <div style={{maxWidth:'400px', margin: 'auto'}}>
        <a href="https://app.livestorm.co/pansurg-1/the-cns-and-anp-journey-redeployment-work-life-balance-and-supporting-cancer-patients">
              <Img className="bounce" fluid={data.webinarnext.childImageSharp.fluid} />
        </a>
          </div>

        </div>

        <div className="column">
            <div className="tile box has-background-white-ter is-vertical">
          <p className="title is-size-4 has-text-darkBlue">Please register for the upcoming webinar (Friday 29th May, 1700 BST)</p>
          <iframe width="100%" height="400" frameborder="0"
          src="https://app.livestorm.co/p/c4bcae29-0e2f-4e67-af2b-b79f88e7444b/form"></iframe>
        </div>
        </div>
  </div> */}
<hr />
<h1 className="title has-text-darkBlue is-spaced">
        Previous Webinars
      </h1>
      <h3 className="subtitle">
        Please see our <Link to="/archive">archive</Link> for previous webinars.
      </h3>
      <hr />
<h1 className="title has-text-darkBlue is-spaced">
        <a href="https://www.imperial.ac.uk/hamlyn-centre/events--global-engagement/fair-space-webinar-series/">The Hamlyn Centre Webinars</a>
      </h1>
      <p>PPE (personal protective equipment) has gained press recently. But what is in a PPE? A PPE is a garment, a wearable. Clothing has been one of the earliest technology humans have invented. Although we now mostly see clothing as a social object rather than a technological object – except it is the other way round for PPEs and spacesuits. In a four-part series, we explore the different aspects of such technical garments with practicing experts. In relation to current affairs and ask, what is the desirable futures for such garments? With technology getting more intertwined with health and wellbeing, from robotic surgery to assistive wearables, the webinar series provides us far-reaching insights into the complexity of technologies for health and wellbeing.</p>
      <a className="button is-darkBlue is-rounded" href="https://www.imperial.ac.uk/hamlyn-centre/events--global-engagement/fair-space-webinar-series/">Read more here</a>
      <h3>Past Sessions:</h3>
      <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Session 1: Clothing as a System - ecology and evolution</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                Since PPEs like disposable masks and gloves is becoming part of everyday life in the UK, such garments are seen lying around on the roads, in the parks and in bodies of water in London. PPEs are made as disposables to manage the risk of contamination. Spacesuits, especially EVA suits, are a strange kind of disposable, once they are sent up to space, they are reused and upcycled as much as possible. The environment in which PPEs and spacesuit are donned is often well defined. On Earth and in space, such garments are designed to be donned with airlocks and gowning procedures to ensure the risk of contamination is managed systematically. Will modular design and design for easy-cleaning change the landscape of these disposables? What else happens in the operation environments to support the successful use of such garments?<br /><br />
<b>Confirmed Panelists:</b>
<ul>
<li>Astronaut Richard Linnehan, from NASA Johnson Space Center</li>
<li>Daniel J Klopp, from ILC Dover Inc, NASA’s spacesuit (xEMU) maker</li>
<li>Julia Duncan from Monmouth Scientific, a clean room solutions provider</li>
</ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Session 2: Protective Clothing – layer up!</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                Like all garments, the spacesuit has a protective function. With the recent pandemic, this protective function is very present in our minds as in PPEs and masks. In the extreme environment of space, the protective garment is effectively a life support system for one. What enables these protective clothing to be effective?  What are the pros and cons of protective clothing? What other extreme environments can we learn from?<br /><br />
<b>Confirmed Panelists:</b>
<ul>
<li>Dr. Thomas Reiter from European Space Agency</li>
<li>Daniel J Klopp from ILC Dover Inc, NASA’s spacesuit (xEMU) maker</li>
<li>Marie O’Mahony from O’Mahony Consultancy and Royal College of Art, author of Advanced Textiles for Health and Wellbeing.</li>
</ul>
                </AccordionItemPanel>
                </AccordionItem>
                </Accordion>
<h3>Future Sessions:</h3>
<Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Session 3: Adaptive Clothing – design for women (9th October, 1930 BST)</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                Are the current PPEs universally designed? Is a unisex three-size-fit-all design enough? Is universal design inclusive design? So much of PPE and spacesuit design and manufacturing is about standardisation, the truth is the human body is not standardised. One approach spacesuit makers take is to modularise to allow for customisation. How can the garments and equipment be designed for diversity in space and in operating theaters? Could the existing approach to designing PPEs by standard sizes be excluding the majority from the usage of such garments and equipment, rather than including?<br /><br />
<a className="button is-darkBlue is-rounded" href="https://www.eventbrite.co.uk/e/from-ppe-to-spacesuits-3-adaptive-clothing-design-for-women-tickets-121373307831">Register on Eventbrite</a>

                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
<h5>Session 4: Assistive Clothing – and human emotions (15-25th October, date TBC)</h5>
</AccordionItemButton>
                </AccordionItemHeading>
                 <AccordionItemPanel>
                 In order to provide the protective function, the garment inevitably created a layer of resistance to our body’s movement and a layer of obfuscation to our expressions. Often these are discussed in terms of performance – like how can we make the protective garment less abrasive, more ventilated to allow work to be done without exhausting the wearer on the job? But of course, we mustn’t forget that protective garments are worn at unusual times, times when our humanly expression is just as important. A discussion on assistive functions is incomplete without discussing the support for humanly expressions.
                 </AccordionItemPanel>
                </AccordionItem>
                </Accordion>
      <hr />
<h2 className="subtitle is-size-4">Our collaboration with Johnson & Johnson Medical Devices</h2>

<p>After a short break over the summer, we are delighted to be collaborating with Johnson & Johnson Medical Devices. We will deliver a series of virtual events over the coming months that will bring together senior clinicians, healthcare managers and policymakers from across the world.</p>

<p>We will present high quality education and research to those providing care to all surgical patients during the COVID-19 pandemic and in its aftermath through highly innovative, data-driven and adaptable digital learning opportunities.</p>

<p>Our ultimate goal with these events, and the research and data they uncover, is to influence local and national health policy on critical surgical issues by engaging with thought leaders, policy makers and stakeholders.</p>

{/* <p>Be part of this ground-breaking series! Find out more about the events and how to register below.</p> */}
    </div>
  </section>

 
    <hr className="is-divider"/>

    <section className="section is-small" >
      <div className="container">
         <div className="is-thin">

      <p className="subtitle has-text-darkBlue">Industry Partner</p>

      <div className="logos is-flex-tablet has-text-centered">
        <a href=" https://www.jnjmedicaldevices.com/en-GB"><img width="400px" src={logo_jandJ} /></a>
      {/*  <a href="https://www.medtronic.com"><img width="200px" src={logo_medtronic} /></a> */}
      </div>


        </div>
      </div>
    </section>


  </>
  )
}
